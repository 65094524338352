import { Address } from "k4n-svcmesh-sdk"
import { CompanyShareholder } from "../typings/onboarding-pj-save-2.dto"
import { CompanyManagingPartner } from "../typings/onboarding-pj-save-3.dto"
import { RelatedCompany } from "../typings/onboarding-pj-save-4.dto"
import { CompanyBank } from "../typings/onboarding-pj-save-5.dto"

export const buildCpfWithMask = (cpf: string) => {
  const first3 = cpf.substring(0, 3)
  const second3 = cpf.substring(3, 6)
  const third3 = cpf.substring(6, 9)
  const first2 = cpf.substring(9, 11)

  return first3 + `.` + second3 + `.` + third3 + `-` + first2
}

export const buildAddress = (address: Address) => {
  let fixedAddress = address.logradouro + `, ` + address.numero
  if (address.complemento)
    fixedAddress = fixedAddress + ` ` + address.complemento
  fixedAddress = fixedAddress + `, ` + address.bairro
  fixedAddress = fixedAddress + `, ` + address.cep
  fixedAddress = fixedAddress + `, ` + address.cidade
  fixedAddress = fixedAddress + `, ` + address.uf

  return fixedAddress
}

export const buildCpfCnpjMask = (cpfCnpj: string) => {
  let value = cpfCnpj.replace(/\D/g, ``)
  if (value.length <= 11) {
    value = value.replace(/(\d{3})(\d)/, `$1.$2`)
    value = value.replace(/(\d{3})(\d)/, `$1.$2`)
    value = value.replace(/(\d{3})(\d{1,2})$/, `$1-$2`)
  } else {
    value = value.replace(/^(\d{2})(\d)/, `$1.$2`)
    value = value.replace(/^(\d{2})\.(\d{3})(\d)/, `$1.$2.$3`)
    value = value.replace(/\.(\d{3})(\d)/, `.$1/$2`)
    value = value.replace(/(\d{4})(\d)/, `$1-$2`)
  }
  return value
}

export const buildCpfMask = (cpf: string) => {
  let value = cpf.replace(/\D/g, ``)
  value = value.replace(/(\d{3})(\d)/, `$1.$2`)
  value = value.replace(/(\d{3})(\d)/, `$1.$2`)
  value = value.replace(/(\d{3})(\d{1,2})$/, `$1-$2`)
  return value
}

export const buildRgMask = (rg: string) => {
  const v = rg.toUpperCase().replace(/[^\dX]/g, ``)
  return v.replace(/^(\d{1,2})(\d{3})(\d{3})([\dX])$/, `$1.$2.$3-$4`)
}

export const buildCnpjMask = (cnpj: string) => {
  let value = cnpj.replace(/\D/g, ``)
  value = value.replace(/^(\d{2})(\d)/, `$1.$2`)
  value = value.replace(/^(\d{2})\.(\d{3})(\d)/, `$1.$2.$3`)
  value = value.replace(/\.(\d{3})(\d)/, `.$1/$2`)
  value = value.replace(/(\d{4})(\d)/, `$1-$2`)
  return value
}

export const buildPhoneMask = (phone: string) => {
  let value = phone.replace(/\D/g, ``)
  if (value.length <= 11) {
    value = value.replace(/(\d{2})/, `($1) `)
    value = value.replace(/(\d{4})(\d{4})$/, `$1-$2`)
  }
  return value
}

export type Operations = `+` | `-` | `x` | `÷` | `=` | `*` | `/` | ``

export const codBarras2LinhaDigitavel = (codigo, formatada) => {
  codigo = codigo.replace(/[^0-9]/g, ``)

  let resultado = ``

  const novaLinha =
    codigo.substr(0, 4) +
    codigo.substr(19, 25) +
    codigo.substr(4, 1) +
    codigo.substr(5, 14)

  const bloco1 = novaLinha.substr(0, 9) + calculaMod10(novaLinha.substr(0, 9))
  const bloco2 = novaLinha.substr(9, 10) + calculaMod10(novaLinha.substr(9, 10))
  const bloco3 =
    novaLinha.substr(19, 10) + calculaMod10(novaLinha.substr(19, 10))
  const bloco4 = novaLinha.substr(29)

  resultado = (bloco1 + bloco2 + bloco3 + bloco4).toString()

  if (formatada) {
    resultado =
      resultado.slice(0, 5) +
      `.` +
      resultado.slice(5, 10) +
      ` ` +
      resultado.slice(10, 15) +
      `.` +
      resultado.slice(15, 21) +
      ` ` +
      resultado.slice(21, 26) +
      `.` +
      resultado.slice(26, 32) +
      ` ` +
      resultado.slice(32, 33) +
      ` ` +
      resultado.slice(33)
  }
  return resultado
}

export const calculaMod10 = (numero) => {
  numero = numero.replace(/\D/g, ``)
  let i
  let mult = 2
  let soma = 0
  let s = ``

  for (i = numero.length - 1; i >= 0; i--) {
    s = mult * parseInt(numero.charAt(i)) + s
    if (--mult < 1) {
      mult = 2
    }
  }
  for (i = 0; i < s.length; i++) {
    soma = soma + parseInt(s.charAt(i))
  }
  soma = soma % 10
  if (soma != 0) {
    soma = 10 - soma
  }
  return soma
}

export const buildCompanyBankObj = (data) => {
  return {
    manager_name: data.managerName,
    phone: data.contactPhone,
    agency: data.agency,
    account: data.account,
    opening_date: data.openingDate,
    participant: data.participant,
    institution: data.institution,
  } as CompanyBank
}

export const buildRelatedCompanyObj = (data) => {
  return {
    nome_razao_social: data.nomeRazao,
    cnpj: data.cnpj.replaceAll(`-`, ``).replaceAll(`.`, ``),
    share: data.share,
    city: data.city,
    state: data.state,
    participant: data.participant,
  } as RelatedCompany
}

export const buildCompanyManagingPartnerObj = (data, noEndOfTerm) => {
  return {
    full_name: data.name,
    cpf: data.cpf.replaceAll(`-`, ``).replaceAll(`.`, ``),
    position: data.position,
    entry_date: data.entryDate,
    end_of_term: noEndOfTerm ? undefined : data.entryDate,
    indefinite_term: noEndOfTerm,
  } as CompanyManagingPartner
}

export const buildCompanyShareholderObj = (data) => {
  return {
    nome_razao_social: data.nomeRazao,
    cpf_cnpj: data.cpfCnpj.replaceAll(`-`, ``).replaceAll(`.`, ``),
    share: data.pct,
    entry_date: data.entryDate,
    rg: data.documentNumber.replaceAll(`-`, ``).replaceAll(`.`, ``),
    emissor_uf: data.uf,
  } as CompanyShareholder
}

// Helper function to convert file to base64
export const toBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!(file instanceof Blob)) {
      reject(
        new TypeError(
          `Provided parameter is not a Blob or File it's a ${typeof file}`,
        ),
      )
      return
    }

    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result as string) // Get base64 string only
    reader.onerror = (error) => reject(error)
  })
}
