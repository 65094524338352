import SVGInject from "@iconfu/svg-inject"
import { AxiosResponse } from "axios"
import { PrimaryButton } from "k4n-components"
import { CheckCpfResponse } from "k4n-svcmesh-sdk"
import { useContext } from "react"
import { FieldValues, useForm } from "react-hook-form"
import { useNavigate } from "react-router-dom"
import Arrow from "../../../assets/images/arrow.svg"
import Logo from "../../../assets/images/kikkin_white_logo.svg"
import { AuthContext } from "../../../context/AuthProvider"
import { ThemeContext } from "../../../context/ThemeContext"
import { useResposive } from "../../../hooks/useResponsive"
import services from "../../../services/svcmesh"
import { validateCNPJ, validateCpf } from "../../../utils/validation"
import Navbar from "../../features/Navbar"
import "./styles.scss"

const Home = () => {
  const { login, firstLogin } = useContext(AuthContext)
  const { logo, theme } = useContext(ThemeContext)
  const { isTabletOrMobile } = useResposive()
  const navigate = useNavigate()
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
  } = useForm({
    mode: `onChange`,
  })

  const onSubmit = async (data: FieldValues) => {
    const legalDocument = data.legalDocument
    const isCpf = legalDocument.replace(/\D/g, ``).length === 11

    let res: AxiosResponse<CheckCpfResponse, any> =
      await services.bankingService.checkIfExistByCnpj(
        legalDocument?.replace(/\D/g, ``),
      )
    if (isCpf) {
      res = await services.bankingService.checkIfExistByCpf(
        legalDocument?.replace(/\D/g, ``),
      )
    }

    if (!res.data.exist || (res.data.exist && res.data.partial)) {
      isCpf
        ? navigate(`/onboarding`, { state: { cpf: legalDocument } })
        : navigate(`/onboarding/pj`, { state: { cnpj: legalDocument } })
    } else if (res.data.approved === 0 || res.data.approved === 1) {
      navigate(`/waitAccount`)
    } else if (res.data.exist && !res.data.partial && !res.data.first) {
      login(legalDocument)
    } else if (res.data.exist && !res.data.partial && res.data.first) {
      firstLogin(legalDocument)
    }
  }

  const handleMaskInputManually = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.replace(/\D/g, ``)
    let maskedValue = value

    if (value.length > 11) {
      // CNPJ mask
      if (value.length <= 2) {
        maskedValue = value
      } else if (value.length <= 5) {
        maskedValue = value.replace(/^(\d{2})(\d{0,3})/, `$1.$2`)
      } else if (value.length <= 8) {
        maskedValue = value.replace(/^(\d{2})(\d{3})(\d{0,3})/, `$1.$2.$3`)
      } else if (value.length <= 12) {
        maskedValue = value.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{0,4})/,
          `$1.$2.$3/$4`,
        )
      } else {
        maskedValue = value.replace(
          /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{0,2})/,
          `$1.$2.$3/$4-$5`,
        )
      }
    } else {
      // CPF mask
      if (value.length <= 3) {
        maskedValue = value
      } else if (value.length <= 6) {
        maskedValue = value.replace(/^(\d{3})(\d{0,3})/, `$1.$2`)
      } else if (value.length <= 9) {
        maskedValue = value.replace(/^(\d{3})(\d{3})(\d{0,3})/, `$1.$2.$3`)
      } else {
        maskedValue = value.replace(
          /^(\d{3})(\d{3})(\d{3})(\d{0,2})/,
          `$1.$2.$3-$4`,
        )
      }
    }

    setValue(`legalDocument`, maskedValue)
  }

  const validateCPFandCNPJ = () => {
    if (getValues(`legalDocument`)?.replace(/\D/g, ``).length === 11) {
      return validateCpf(getValues(`legalDocument`)?.replace(/\D/g, ``))
    } else {
      return validateCNPJ(getValues(`legalDocument`)?.replace(/\D/g, ``))
    }
  }

  return (
    <>
      {isTabletOrMobile !== true ? (
        <div className="home">
          <Navbar />
          <div
            className="home-content bg-image"
            style={{
              height: `100%`,
              backgroundSize: `cover`,
              backgroundImage: `url(${theme?.images?.login_background_image})`,
            }}
          >
            <h1 className="home-h1">
              Nosso propósito é fortalecer
              <br /> o setor e ajudar nossos clientes
              <br /> a serem mais felizes e prósperos
            </h1>
            <form className="home-form" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <input
                  type="text"
                  maxLength={18} // Limit input to 18 characters (masked CNPJ length)
                  placeholder={`Digite seu CPF/CNPJ`}
                  className="home-input"
                  {...register(`legalDocument`, {
                    onChange: handleMaskInputManually,
                    required: true,
                    validate: validateCPFandCNPJ,
                  })}
                />
                {errors.legalDocument && (
                  <p className="home-error-msg">
                    Por favor, verifique o campo.
                  </p>
                )}
              </div>
              <button className="home-btn-next" type="submit">
                Vamos lá!
                <img src={Arrow} className="home-btn-arrow" />
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className="home-mobile">
          <img
            style={{ maxWidth: 150, height: 60 }}
            src={logo || Logo}
            onLoad={(e) => SVGInject(e.target)}
          />
          <h1 className="home-h1-mobile">Estávamos esperando por você!</h1>
          <form className="home-form-mobile" onSubmit={handleSubmit(onSubmit)}>
            <input
              type="text"
              maxLength={18} // Limit input to 18 characters (masked CNPJ length)
              placeholder={`Digite seu CPF/CNPJ`}
              className="home-input-mobile"
              inputMode="numeric"
              {...register(`legalDocument`, {
                onChange: handleMaskInputManually,
                required: true,
                validate: validateCPFandCNPJ,
              })}
            />
            {errors.legalDocument && (
              <p className="home-error-msg-mobile">
                Por favor, verifique o campo.
              </p>
            )}
            <PrimaryButton width={`312px`} height={`70px`}>
              <div className="home-btn-txt-mobile">
                Vamos lá!
                <img src={Arrow} className="home-btn-arrow" />
              </div>
            </PrimaryButton>
          </form>
        </div>
      )}
    </>
  )
}

export default Home
