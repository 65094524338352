import { useContext } from "react"
import { OnboardingPjContext } from "../../../../context/OnboardingPjContext"
import { ThemeContext } from "../../../../context/ThemeContext"
import Logo from "../../../../assets/images/kikkin_white_logo.svg"
import SVGInject from "@iconfu/svg-inject"

const OnboardingStartMap = () => {
  const { logo } = useContext(ThemeContext)
  const { setOnboardingStart } = useContext(OnboardingPjContext)
  return (
    <div className="contaniner-pj">
      <div className="onboarding-pj-img">
        <div className="onboarding-dark-overlay">
          <div className="start-text-container">
            <img
              className="mt-60px"
              width="100%"
              height="100%"
              style={{ maxWidth: 150, maxHeight: 40 }}
              src={Logo || logo}
              onLoad={(e) => SVGInject(e.target)}
            />
            <div className="start-text-title mt-md-5 mt-4 mb-5 title-color">
              <p>Cadastre sua</p>
              <p>empresa hoje</p>
              <p>mesmo!</p>
            </div>
            <div className="mt-5 text-weight mb-3">Qual o passo a passo?</div>
            <ul className="text-style">
              <li>Preencha as informações solicitadas (3 passos).</li>
              <li>
                Faça o upload da&nbsp;
                <span className="text-decoration-item-pj">
                  lista de documentos solicitados
                </span>
                .
              </li>
              {/* <li>
                  Confira se está tudo certo na tela de Resumo e envie a
                  solicitação.
                </li> */}
              <li>Depois é só aguardar o retorno via email.</li>
            </ul>
          </div>
        </div>
        <div className="button-pj-container">
          <button className="start-button" onClick={setOnboardingStart}>
            Vamos Lá!
          </button>
        </div>
      </div>
    </div>
  )
}

export default OnboardingStartMap
