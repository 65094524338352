export enum ClientePfStatus {
  INATIVO = 0,
  ATIVO = 1,
  ONBOARDING = 2,
  PARTIAL = 3,
  CREATE_DIGITAL_SIGNATURE_PENDING = 4,
  FIRST_LOGIN = 5,
}

export const states = [
  { name: `Acre`, value: `AC` },
  { name: `Alagoas`, value: `AL` },
  { name: `Amapá`, value: `AP` },
  { name: `Amazonas`, value: `AM` },
  { name: `Bahia`, value: `BA` },
  { name: `Ceará`, value: `CE` },
  { name: `Distrito Federal`, value: `DF` },
  { name: `Espírito Santo`, value: `ES` },
  { name: `Goías`, value: `GO` },
  { name: `Maranhão`, value: `MA` },
  { name: `Mato Grosso`, value: `MT` },
  { name: `Mato Grosso do Sul`, value: `MS` },
  { name: `Minas Gerais`, value: `MG` },
  { name: `Pará`, value: `PA` },
  { name: `Paraíba`, value: `PB` },
  { name: `Paraná`, value: `PR` },
  { name: `Pernambuco`, value: `PE` },
  { name: `Piauí`, value: `PI` },
  { name: `Rio de Janeiro`, value: `RJ` },
  { name: `Rio Grande do Norte`, value: `RN` },
  { name: `Rio Grande do Sul`, value: `RS` },
  { name: `Rondônia`, value: `RO` },
  { name: `Roraíma`, value: `RR` },
  { name: `Santa Catarina`, value: `SC` },
  { name: `São Paulo`, value: `SP` },
  { name: `Sergipe`, value: `SE` },
  { name: `Tocantins`, value: `TO` },
]
