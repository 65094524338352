import { useContext, useState } from "react"
import OnboardingModal from "../../../features/OnboardingModal"
import "./styles.scss"
import { useNavigate } from "react-router-dom"
import { useResposive } from "../../../../hooks/useResponsive"
import { OnboardingPjContext } from "../../../../context/OnboardingPjContext"
import whiteLogo from "../../../../assets/images/logo-kikkin-verde.svg"
import arrow from "../../../../assets/images/arrow_right_white.svg"
import arrowLeft from "../../../../assets/images/yellow-arrow-left.svg"
import "./styles.scss"
const Welcome = () => {
  const { setOnboardingStart, goBack, next } = useContext(OnboardingPjContext)

  const navigate = useNavigate()

  const [hasCamModal, setHasCamModal] = useState(false)
  const [qrCodeModal, setQrCodeModal] = useState(false)
  const { isTabletOrMobile } = useResposive()

  const handleWelcomeGoBack = () => {
    goBack()
    navigate(`/`)
  }

  const handleStart = () => {
    // if (isTabletOrMobile) {
    //   startSteps()
    // } else {
    //   setHasCamModal(true)
    // }
  }

  return (
    <form className="onboarding-pj-form-container">
      <img
        className="onboarding-pj-go-back"
        onClick={setOnboardingStart}
        src={arrowLeft}
      />
      <img width={78} height={25} className="k4n-white-logo" src={whiteLogo} />
      <div className="onboarding-reminder-primary">Lembrete:</div>
      <span className="onboarding-pj-medium-title">Sobre os documentos</span>
      <span className="onboarding-pj-white-txt">
        Caro cliente a seguir pedimos uma série de informações cadastrais e
        financeiras para conhecê-lo melhor, entender como podemos ajudá-lo com
        crédito e nos anteciparmos para lhe oferecer as melhores condições
        comerciais em nossos serviços.
      </span>
      <span className="onboarding-pj-white-txt mt-20">
        Pedimos a gentileza de preencher as informações com bastante atenção.
        Obrigado (a)!
      </span>
      <div className="onboarding-pj-flex-end">
        <button onClick={next} className="onboarding-pj-btn-next">
          <div className="onboarding-pj-btn-next-txt-div">
            <span className="onboarding-pj-btn-next-txt">Entendido</span>
            <img src={arrow} className="ml-32" />
          </div>
        </button>
      </div>
    </form>
  )
}

export default Welcome
