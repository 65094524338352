import { OnboardingPjSave } from "k4n-svcmesh-sdk/dist/typings/onboarding-pj.dto"
import { createContext, ReactElement, ReactNode, useState } from "react"
import OnboardingModal from "../components/features/OnboardingModal"
import { OnboardingPjSave2 } from "../typings/onboarding-pj-save-2.dto"
import { OnboardingPjSave3 } from "../typings/onboarding-pj-save-3.dto"
import { OnboardingPjSave4 } from "../typings/onboarding-pj-save-4.dto"
import { OnboardingPjSave5 } from "../typings/onboarding-pj-save-5.dto"
import { OnboardingPjSave6 } from "../typings/onboarding-pj-save-6.dto"

export const TOTAL_STEPS = 2

type Step = 0 | 1 | 2 | 3 /* | 4 | 5 | 6 | 7 | 8 | 9 | 10 */

type ModalAlert = {
  show: boolean
  message?: string
  buttons?: ModalButton[]
}

type ModalButton = {
  text: string
  onClick: () => void
  type?: `primary` | `link`
}

type OnboardingPjContextType = {
  loading: boolean
  step: Step
  subStep: Step
  clientePjId: number
  addClientePjId: (data: number) => void
  step1Data: OnboardingPjSave
  step2Data: OnboardingPjSave2
  step3Data: OnboardingPjSave3
  step4Data: OnboardingPjSave4
  step5Data: OnboardingPjSave5
  step6Data: OnboardingPjSave6
  addStep1: (data: OnboardingPjSave) => void
  addStep2: (data: OnboardingPjSave2) => void
  addStep3: (data: OnboardingPjSave3) => void
  addStep4: (data: OnboardingPjSave4) => void
  addStep5: (data: OnboardingPjSave5) => void
  addStep6: (data: OnboardingPjSave6) => void
  goBack: () => void
  next: () => void
  showTotalErrorShareHolder: () => void
  showErrorManagingPartner: () => void
  confirmOnboardingStart: boolean
  setOnboardingStart: () => void
}

const contextDefaultValues: OnboardingPjContextType = {
  loading: false,
  step: 0,
  subStep: 0,
  clientePjId: 0,
  addClientePjId: () => {},
  step1Data: {} as OnboardingPjSave,
  step2Data: {},
  step3Data: {},
  step4Data: {},
  step5Data: {},
  step6Data: {},
  addStep6: () => {},
  addStep5: () => {},
  addStep4: () => {},
  addStep3: () => {},
  addStep2: () => {},
  addStep1: () => {},
  next: () => {},
  goBack: () => {},
  showTotalErrorShareHolder: () => {},
  showErrorManagingPartner: () => {},
  confirmOnboardingStart: false,
  setOnboardingStart: () => {},
}

export const OnboardingPjContext =
  createContext<OnboardingPjContextType>(contextDefaultValues)

const OnboardingPjProvider = ({
  children,
}: {
  children: ReactNode
}): ReactElement => {
  const [step, setStep] = useState<Step>(contextDefaultValues.step)
  const [subStep, setSubStep] = useState<Step>(contextDefaultValues.subStep)
  const [loading, setLoading] = useState(false)
  const [modalAlert, setModalAlert] = useState<ModalAlert>({ show: false })
  const [step1Data, setStep1Data] = useState<OnboardingPjSave>(
    contextDefaultValues.step1Data,
  )
  const [step2Data, setStep2Data] = useState<OnboardingPjSave2>(
    contextDefaultValues.step2Data,
  )
  const [step3Data, setStep3Data] = useState<OnboardingPjSave3>(
    contextDefaultValues.step3Data,
  )
  const [step4Data, setStep4Data] = useState<OnboardingPjSave4>(
    contextDefaultValues.step4Data,
  )
  const [step5Data, setStep5Data] = useState<OnboardingPjSave5>(
    contextDefaultValues.step5Data,
  )
  const [step6Data, setStep6Data] = useState<OnboardingPjSave6>(
    contextDefaultValues.step6Data,
  )
  const [clientePjId, setClientePjId] = useState<number>(
    contextDefaultValues.clientePjId,
  )
  const [confirmOnboardingStart, setConfirmOnboardingStart] = useState(false)

  const addStep1 = (data: OnboardingPjSave) => setStep1Data(data)
  const addStep2 = (data: OnboardingPjSave2) => setStep2Data(data)
  const addStep3 = (data: OnboardingPjSave3) => setStep3Data(data)
  const addStep4 = (data: OnboardingPjSave4) => setStep4Data(data)
  const addStep5 = (data: OnboardingPjSave5) => setStep5Data(data)
  const addStep6 = (data: OnboardingPjSave6) => setStep6Data(data)
  const addClientePjId = (data: number) => setClientePjId(data)
  const next = () => {
    if (step < TOTAL_STEPS + 1) {
      setStep((step + 1) as Step)
    } else {
      clear()
      // Exit on boarding
    }
  }

  const goBack = () => {
    if (step) {
      setStep((step - 1) as Step)
    } else {
      clear()
    }
  }

  const clear = () => {
    console.log(`Clear on boarding context`)
    setStep(0)
    setSubStep(0)
  }

  const showTotalErrorShareHolder = () => {
    console.log(`passou aqui`)
    showAlertModal(`O quadro societário deve totalizar 100%`, {
      show: true,
    })
  }

  const showErrorManagingPartner = () => {
    console.log(`passou aqui`)
    showAlertModal(
      `Você deve adicionar pelo menos um diretor ou sócio-gerente.`,
      {
        show: true,
      },
    )
  }

  const showAlertModal = (
    message = `Ocorreu um erro inesperado. Por favor tente mais tarde.`,
    options: ModalAlert = { show: true },
  ) => {
    setModalAlert({
      show: options.show,
      message: message,
      buttons: options.buttons,
    })
  }

  const setOnboardingStart = () => {
    setConfirmOnboardingStart(!confirmOnboardingStart)
  }

  return (
    <OnboardingPjContext.Provider
      value={{
        loading,
        step,
        subStep,
        clientePjId,
        addClientePjId,
        step1Data,
        step2Data,
        step3Data,
        step4Data,
        step5Data,
        step6Data,
        addStep6,
        addStep5,
        addStep4,
        addStep3,
        addStep2,
        addStep1,
        goBack,
        next,
        showTotalErrorShareHolder,
        showErrorManagingPartner,
        confirmOnboardingStart,
        setOnboardingStart,
      }}
    >
      {children}
      <OnboardingModal
        isOpen={modalAlert.show}
        onClose={() => setModalAlert({ show: false })}
      >
        <div className="px-4 pt-1 pb-3">{modalAlert.message}</div>
        <div className="flex-center">
          {modalAlert.buttons?.map((btn, i) => (
            <button
              key={i}
              onClick={btn.onClick}
              className="onboarding-btn-primary"
            >
              {btn.text}
            </button>
          ))}
        </div>
      </OnboardingModal>
    </OnboardingPjContext.Provider>
  )
}

export default OnboardingPjProvider
