import { useContext, useEffect } from "react"
import { OnboardingPjContext } from "../../../../context/OnboardingPjContext"
import { useResposive } from "../../../../hooks/useResponsive"
import OnboardingSideMenu from "../../../features/OnboardingSideMenu/PJ"
import OnboardingStartMap from "./onboarding-start-map"
import Welcome from "./welcome"
import Identification from "./identification"
import { Documents } from "./documents"
import { Final } from "./final"
// import Shareholders from "./shareholders"
// import Administration from "./administration"
// import RelatedCompanies from "./related-companies"
// import Banks from "./banks"
// import CommercialReferences from "./commercial-references"

export const onboardingNavigation = {
  0: [<Welcome key="0" />],
  1: [<Identification key="1" />],
  2: [<Documents key="2" />],
  3: [<Final key="3" />],
  // 2: [<Shareholders key="2" />],
  // 3: [<Administration key="3" />],
  // 4: [<RelatedCompanies key="4" />],
  // 5: [<Banks key="5" />],
  // 6: [<CommercialReferences key="6" />],
}

const OnboardingPj = () => {
  const { confirmOnboardingStart, step, subStep } =
    useContext(OnboardingPjContext)

  const { isTabletOrMobile } = useResposive()

  useEffect(() => {
    console.log(`🚀 ~ file: index.tsx:25 ~ OnboardingPj ~ step`, step)
    console.log(`🚀 ~ file: index.tsx:26 ~ OnboardingPj ~ subStep`, subStep)
    console.log(
      `🚀 ~ file: index.tsx:27 ~ OnboardingPj ~ confirmOnboardingStart`,
      confirmOnboardingStart,
    )
  }, [confirmOnboardingStart, step, subStep])

  const pageSwitch = () => {
    return onboardingNavigation[step][subStep]
  }

  if (confirmOnboardingStart) {
    if (isTabletOrMobile)
      return <div className="onboarding-mobile-content">{pageSwitch()}</div>

    return <OnboardingSideMenu step={step}>{pageSwitch()}</OnboardingSideMenu>
  } else {
    return <OnboardingStartMap />
  }
}

export default OnboardingPj
