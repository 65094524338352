import "./styles.scss"
import { PrimaryButton } from "k4n-components"
import { useForm } from "react-hook-form"
import InputMask from "react-input-mask"
import Select from "react-select"
import { useEffect } from "react"
import services from "../../../../../../../services/svcmesh"

const BoletoCreationStep1Mobile = ({
  onSubmit,
  handleFocus,
  handleFocusEnd,
  handleCpfCnpjChange,
  phoneMask,
  handlePhoneChange,
  handleSelectFocusEnd,
  focusList,
  cpfCnpjMask,
  handleCancel,
  inputFavorite,
  fixedFavorites,
  selectedFavorite,
  saveAsFavorite,
  setSaveAsFavorite,
  favorites,
  setFixedFavorites,
  buildCpfCnpjMask,
  setCpfCnpjMask,
  buildPhoneMask,
  setPhoneMask,
  setSelectedFavorite,
  setInputFavorite,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm()

  const handleCompleteAddress = async (
    event: React.FocusEvent<HTMLInputElement>,
  ) => {
    clearErrors(`zip`)
    const zipCode = event.currentTarget.value.replace(`-`, ``)
    const res = await services.zipCodeService.consultZipCode(zipCode)
    if (res.data.erro === `true`) {
      setError(`zip`, { message: `Por favor, verifique o CEP.` })
    }
    setValue(`street`, res.data.logradouro)
    setValue(`state`, res.data.uf)
    setValue(`city`, res.data.localidade)
    setValue(`district`, res.data.bairro)
  }

  interface Option {
    value?: string
    label?: string
  }

  useEffect(
    function () {
      if (favorites.length > 0 && fixedFavorites.length === 0) {
        const fixedFavoritesArray: Option[] = []
        favorites.forEach((f) => {
          if (f.cpf && f.nome) {
            fixedFavoritesArray.push({
              value: f.cpf,
              label: f.nome,
            })
          }
        })
        if (fixedFavoritesArray.length > 0)
          setFixedFavorites(fixedFavoritesArray)
      }

      if (selectedFavorite && selectedFavorite.cpf) {
        const value = buildCpfCnpjMask(selectedFavorite.cpf)
        setValue(`documento`, value)
        setCpfCnpjMask(value)
        setValue(`recipient`, selectedFavorite.nome)
        if (selectedFavorite.emails && selectedFavorite.emails.length > 0) {
          setValue(`email`, selectedFavorite.emails[0].email)
        }
        if (selectedFavorite.phones && selectedFavorite.phones.length > 0) {
          const maskedPhone = buildPhoneMask(selectedFavorite.phones[0].phone)
          setValue(`phone`, maskedPhone)
          setPhoneMask(maskedPhone)
        }
        if (
          selectedFavorite.addresses &&
          selectedFavorite.addresses.length > 0
        ) {
          setValue(`zip`, selectedFavorite.addresses[0].cep)
          setValue(`street`, selectedFavorite.addresses[0].logradouro)
          setValue(`city`, selectedFavorite.addresses[0].cidade)
          setValue(`state`, selectedFavorite.addresses[0].uf)
          setValue(`district`, selectedFavorite.addresses[0].bairro)
          setValue(`number`, selectedFavorite.addresses[0].numero)
          if (selectedFavorite.addresses[0].complemento)
            setValue(`complement`, selectedFavorite.addresses[0].complemento)
        }
      }
    },
    [favorites, selectedFavorite],
  )

  const customStylesMobile = {
    option: (provided, state) => ({
      ...provided,
      // borderBottom: `1px dotted pink`,
      color: state.isSelected ? `black` : `black`,
      padding: 20,
      background: state.isSelected || state.isFocused ? `#e5e5e5` : `#FFFFFF`,
      "&:hover": {
        background: `#e5e5e5`,
        cursor: `pointer`,
      },
    }),
    input: (provided, state) => ({
      ...provided,
      "&:hover": {
        cursor: `text`,
      },
    }),
    control: (base) => ({
      ...base,
      boxShadow: `none`,
      border: 0,
      borderRadius: `28px`,
      height: `67px`,
      background: `var(--k4n-secondary-light-color)`,
      paddingLeft: `10px`,
    }),
  }

  const handleFavoriteChange = (e: any) => {
    setInputFavorite(e)
    if (e && e.value) {
      const favorite = favorites.filter((f) => f.cpf === e.value)
      setSelectedFavorite(favorite[0])
    } else {
      setSelectedFavorite({})
      reset()
      setCpfCnpjMask(``)
      setValue(`zip`, ``)
      setValue(`phone`, ``)
      setValue(`state`, `0`)
    }
  }

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="bc-step-mobile-content"
      >
        <div className="bc-step-mobile">
          <button
            className="payment-mobile-close-btn"
            onClick={handleCancel}
          ></button>
          <div className="bc-title-div">
            <h1 className="bc-header-h1-mobile">Informe os dados do pagador</h1>
          </div>
          <div className="mt-20 f-12-grey">
            <Select
              noOptionsMessage={({ inputValue }) =>
                !inputValue ? `` : `Nenhum resultado encontrado`
              }
              options={fixedFavorites}
              placeholder="Selecione dos Favoritos"
              styles={customStylesMobile}
              isMulti={false}
              isClearable={true}
              value={inputFavorite}
              onChange={handleFavoriteChange}
            />
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.cpf
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="cpf"
            >
              CPF/CNPJ
            </label>
            <input
              id="cpf"
              type="text"
              value={cpfCnpjMask}
              className="boleto-creation-input-mobile"
              placeholder="CPF/CNPJ"
              onFocus={handleFocus}
              {...register(`documento`, {
                required: true,
                pattern:
                  /([0-9]{2}[\.]?[0-9]{3}[\.]?[0-9]{3}[\/]?[0-9]{4}[-]?[0-9]{2})|([0-9]{3}[\.]?[0-9]{3}[\.]?[0-9]{3}[-]?[0-9]{2})/,
                onBlur: handleFocusEnd,
                onChange: handleCpfCnpjChange,
              })}
            />
            {errors.cpf && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o cpf ou cnpj.
              </span>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.recipient
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="recipient"
            >
              Nome do destinatário
            </label>
            <input
              id="recipient"
              type="text"
              className="boleto-creation-input-mobile"
              onFocus={handleFocus}
              placeholder="Nome do destinatário"
              {...register(`recipient`, {
                required: true,
                onBlur: handleFocusEnd,
                pattern: /^(([A-zÀ-ÿ]+)(\ )?){0,}$/,
              })}
            />
            {errors.recipient && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o destinatário.
              </span>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.email
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="email"
            >
              E-mail
            </label>
            <input
              id="email"
              type="text"
              className="boleto-creation-input-mobile"
              placeholder="E-mail"
              onFocus={handleFocus}
              {...register(`email`, {
                required: true,
                onBlur: handleFocusEnd,
                pattern:
                  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              })}
            />
            {errors.email && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o email.
              </span>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.phone
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="phone"
            >
              Telefone
            </label>
            <input
              id="phone"
              value={phoneMask}
              type="text"
              onFocus={handleFocus}
              placeholder="Telefone"
              className="boleto-creation-input-mobile"
              maxLength={15}
              {...register(`phone`, {
                required: true,
                onBlur: handleFocusEnd,
                pattern: /^\(\d{2}\)\s\d{4,5}\-\d{4}$/,
                onChange: handlePhoneChange,
              })}
            />
            {errors.phone && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o telefone.
              </span>
            )}
          </div>
          <div className="bc-title-div">
            <h1 className="bc-header-h1-mobile">Informe o endereço</h1>
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.zip
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="zip"
            >
              CEP
            </label>
            <InputMask
              mask="99999-999"
              maskPlaceholder={null}
              type="text"
              placeholder="CEP"
              className="boleto-creation-input-mobile"
              onFocus={handleFocus}
              {...register(`zip`, {
                required: true,
                pattern: /^\d{5}-\d{3}$/,
                onChange: (e) => {
                  handleFocusEnd
                  handleCompleteAddress(e)
                },
              })}
            />
            {errors.zip && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o CEP.
              </span>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.street
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="street"
            >
              Endereço
            </label>
            <input
              id="street"
              type="text"
              className="boleto-creation-input-mobile"
              onFocus={handleFocus}
              placeholder="Endereço"
              {...register(`street`, {
                required: true,
                pattern: /^(([a-zA-Zà-ùÀ-Ù0-9]+)(\ )?){0,}$/,
                onBlur: handleFocusEnd,
              })}
            />
            {errors.street && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o endereço.
              </span>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.number
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="email"
            >
              Número
            </label>
            <input
              id="number"
              type="text"
              className="boleto-creation-input-mobile mx-wd-149"
              placeholder="Número"
              onFocus={handleFocus}
              {...register(`number`, {
                required: true,
                pattern: /^[0-9]+$/,
                onBlur: handleFocusEnd,
              })}
            />
            {errors.number && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o número.
              </span>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.complement
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="complement"
            >
              Complemento
            </label>
            <input
              id="complement"
              type="text"
              className="boleto-creation-input-mobile"
              placeholder="Complemento"
              onFocus={handleFocus}
              {...register(`complement`, { onBlur: handleFocusEnd })}
            />
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.district
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="district"
            >
              Bairro
            </label>
            <input
              id="district"
              type="text"
              className="boleto-creation-input-mobile mi-wd-463"
              onFocus={handleFocus}
              placeholder="Bairro"
              {...register(`district`, {
                required: true,
                pattern: /^(([a-zA-Zà-ùÀ-Ù0-9()]+)(\ )?){0,}$/,
                onBlur: handleFocusEnd,
              })}
            />
            {errors.district && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o bairro.
              </span>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.city
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="city"
            >
              Cidade
            </label>
            <input
              id="city"
              type="text"
              className="boleto-creation-input-mobile"
              placeholder="Cidade"
              onFocus={handleFocus}
              {...register(`city`, {
                required: true,
                pattern: /^(([a-zA-Zà-ùÀ-Ù]+)(\ )?){0,}$/,
                onBlur: handleFocusEnd,
              })}
            />
            {errors.city && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique a cidade.
              </span>
            )}
          </div>
          <div className="boleto-box-mobile">
            <label
              className={
                focusList.state
                  ? `boleto-creation-txt-mobile show-input`
                  : `boleto-creation-txt-mobile`
              }
              htmlFor="state"
            >
              Estado
            </label>
            <select
              className="bc-step-1-select-mobile bc-input-value-txt"
              id="state"
              onFocus={handleFocus}
              {...register(`state`, {
                required: true,
                minLength: 2,
                onBlur: handleSelectFocusEnd,
              })}
            >
              <option value="0" disabled selected>
                Selecione o estado
              </option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goías</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraíma</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
            </select>
            {errors.state && (
              <span className="bc-error-msg-mobile">
                Por favor, verifique o estado.
              </span>
            )}
          </div>
          <div className="bc-checkbox-mobile">
            {!selectedFavorite.cpf && (
              <>
                <input
                  onClick={() => setSaveAsFavorite(!saveAsFavorite)}
                  type="button"
                  className={
                    saveAsFavorite
                      ? `bc-step1-checkbox bc-step1-checked ml-0`
                      : `bc-step1-checkbox ml-0`
                  }
                />
                <span className="save-as-favorite-txt">
                  Salvar como favorito
                </span>
              </>
            )}
          </div>
          <div className="boleto-btn-div">
            <PrimaryButton width={`50%`} height={`50px`}>
              Próximo
            </PrimaryButton>
          </div>
        </div>
      </form>
    </>
  )
}
export default BoletoCreationStep1Mobile
